import React from "react"
import { Helmet } from "react-helmet"

//import Layout from "../kdp_theme/Layout"
import KdpBanner from "../../../components/banner"
import KdpHeader from "../../../components/headers/headerGeo"
import KdpContactForm from "../../../components/contacts/rappelForm"
import KdpFooter from "../../../components/footers/footer"


export default function Index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Demandez à être rappelé(e) par un plombier | Dépannage blicko</title>
        <meta name="description" content="Chauffe-eau en panne, fuite d’eau, canalisation bouchée | Rappel immédiat et gratuit | Votre solution de dépannage avec blicko clé en main" />
      </Helmet>
      <header>
        <KdpBanner />
        <KdpHeader />
        <KdpContactForm />
        <KdpFooter />
      </header>
      <main>

      </main>
    </div>
  );
}

