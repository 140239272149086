import React from "react"
/* Bannière */
//import { XIcon } from '@heroicons/react/outline'

/*
Bannière
*/

export default function KdpBanner() {
    return (
      <div className="relative bg-[#3333e6]">
        <div className="hidden max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8 lg:block">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="font-medium text-white">
              <span className="md:hidden"></span>
              <span className="hidden md:inline">blicko recrute 250 plombiers partout en France : </span>
              <span className="block sm:ml-2 sm:inline-block">
                <a href="/recrutement/contact/" className="text-white font-bold underline">
                  {' '}
                  rejoignez-nous ! <span aria-hidden="true">&rarr;</span>
                </a>
              </span>
            </p>
          </div>
        </div>
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8 lg:hidden">
          <div className="text-center sm:px-16">
            <p className="font-medium text-white">
              <span className="block sm:ml-2 sm:inline-block">
                <a href="/devis/contact/" className="text-white font-bold underline">
                  Contactez-nous ! <span aria-hidden="true">&rarr;</span>
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    )
  }