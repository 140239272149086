/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React from "react";
import { MailIcon, PhoneIcon, AtSymbolIcon } from "@heroicons/react/outline";

export default function rappelForm() {
  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Demandez à être rappelé par un plombier
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Vous vous trouvez dans l’une des situations suivantes&nbsp;:
            </p>
            <ul className="text-gray-500">
              <li>- Votre chauffe-eau ne se rallume pas, que faire ?</li>
              <li>- Votre évier est cassé, comment le réparer ?</li>
              <li>
                - Votre flexible de robinet mitigeur est endommagé, faut-il le
                changer ?
              </li>
              <li>
                - Les toilettes sont bouchées, comment résoudre cette situation
                désagréable ?
              </li>
              <li>
                - Un tuyau d’eau est percé, devez-vous faire intervenir un
                plombier en urgence ?
              </li>
            </ul>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Contactez-nous sans attendre pour obtenir une solution rapide et
              efficace.
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Nos équipes sont à votre disposition pour vous conseiller au mieux
              sur les bons gestes à adopter. Nous nous rendons également
              disponibles pour vous dépanner, notamment via{" "}
              <a
                href="https://app.blicko.fr/login"
                target="_blank"
                rel="noreferrer"
                className="text-blue-500"
              >
                notre application de visio plomberie
              </a>
              .
            </p>

            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Adresse</dt>
                <dd>
                  <p>
                    <strong>blicko</strong>
                  </p>
                  <p>22 Rue de Chavril</p>
                  <p>69110 Sainte-Foy-Les-Lyon</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Téléphone</dt>
                <dd className="flex">
                  <PhoneIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">04 28 29 50 16</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">
                    support
                    <AtSymbolIcon className="h-5 inline" />
                    blicko.fr
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form
              action="https://formulaire.koudepouce.fr/blicko/devis/rappel/"
              method="POST"
              className="gap-y-6"
            >
              <h3 className="text-xl font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                Laissez-nous votre numéro de téléphone pour être rappelé
              </h3>
              <p className="mt-3 text-lg leading-6 text-gray-500">
                Une panne de plomberie vous stress ? Pas de panique. Vous pouvez
                nous joindre par téléphone :{" "}
                <a href="tel:0428295016" className="text-blue-500">
                  04 28 29 50 16
                </a>{" "}
                (depuis la France) ou{" "}
                <a href="tel:0428295016" className="text-blue-500">
                  +334 28 29 50 16
                </a>{" "}
                (depuis l'étranger).
              </p>
              <p className="mt-3 text-lg leading-6 text-gray-500">
                Nos dépannages sont réalisés par des équipes de plombiers
                compétents et bienveillants. Nous mettons tout en œuvre pour
                vous répondre rapidement et vous apporter un maximum de
                sérénité.
              </p>
              <p className="mt-3 text-lg leading-6 text-gray-500">
                Afin d’être rappelé(e) et trouver une solution à votre panne de
                plomberie, laissez-nous votre numéro de téléphone ci-dessous.
              </p>
              <div className="py-4">
                <label htmlFor="telephone" className="sr-only">
                  Téléphone
                </label>
                <input
                  type="number"
                  name="telephone"
                  id="telephone"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  placeholder="Téléphone"
                  required
                />
              </div>
              <div className="pb-4">
                <input
                  type="checkbox"
                  id="cgu"
                  name="cgu"
                  className="inline mr-2 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-blue-500 focus:border-blue-500 border-gray-300 rounded-md"
                  required
                />
                J'accepte les{" "}
                <a
                  href="/nous-connaitre/legal/cgu/"
                  className="text-blue-500"
                  target="_blank"
                  rel="noreferrer"
                >
                  Conditions Générales d'Utilisation
                </a>
              </div>
              <div>
                <div className="hidden">
                  <label htmlFor="tellepro" className="sr-only">
                    Téllépro
                  </label>
                  <input
                    type="text"
                    name="tellepro"
                    id="tellepro"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                    placeholder="Téllépro"
                  />
                </div>

                <button
                  type="submit"
                  className="mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                >
                  <PhoneIcon
                    className="flex-shrink-0 h-6 w-6 "
                    aria-hidden="true"
                  />
                  &nbsp;&nbsp;Rappel gratuit immédiat
                </button>
              </div>
            </form>
            <p className="mt-3 text-xs leading-6 text-gray-400">
              Les informations recueillies sur ce formulaire sont enregistrées
              dans un fichier informatisé par blicko visant à traiter votre
              demande. Elles sont conservées pendant 3 ans et sont destinées au
              service marketing et au service commercial de blicko. Nos mentions
              légales sont accessibles ici. Conformément à la loi « informatique
              et libertés », vous pouvez exercer votre droit d’accès aux données
              vous concernant et les faire rectifier en contactant : blicko,
              Service de la protection des données, 22 Chemin de Charrière
              Blanche, 69130 Écully. ou par e-mail sur contact
              <AtSymbolIcon className="h-5 inline" />
              blicko.fr en précisant dans l’objet du courrier « Droit des
              personnes » et en joignant la copie de votre justificatif
              d’identité.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
